@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zen+Dots&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
    padding: 0;
    text-decoration: none;
    border: none;
    outline: none;
    scroll-behavior: smooth;
    font: inherit;
    font-family: 'Montserrat', sans-serif;
}

.header {
    @apply bg-primary text-dark w-full font-mono tracking-widest uppercase opacity-80 p-1;
}

.btn-xl {
    width: 15rem;
    height: 5rem;
}

.panel {
    margin: 1rem;
    padding: 1rem;
    background: rgba(105, 110, 133, 0.50);
}

.form-input {
    margin: 0.1rem;
    padding: 0.5rem;
    background: rgba(105, 110, 133, 0.50);
}

.hide-spans span {
    @apply hidden;
}

.crt::before {
    content: " ";
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(rgba(18, 16, 16, 0) 50%, rgba(0, 0, 0, 0.25) 50%), linear-gradient(90deg, rgba(255, 0, 0, 0.06), rgba(0, 255, 0, 0.02), rgba(0, 0, 255, 0.06));
    z-index: 2;
    background-size: 100% 2px, 3px 100%;
    pointer-events: none;
}


