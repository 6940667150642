@import "https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap";
@import "https://fonts.googleapis.com/css2?family=Zen+Dots&display=swap";
@import "https://fonts.googleapis.com/css2?family=Fira+Code&display=swap";
*, :before, :after {
  box-sizing: border-box;
  border: 0 solid;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: Fira Code;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

:is(::-webkit-inner-spin-button, ::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.-top-5 {
  top: -1.25rem;
}

.-top-\[3rem\] {
  top: -3rem;
}

.bottom-0 {
  bottom: 0;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.top-0 {
  top: 0;
}

.-z-10 {
  z-index: -10;
}

.-z-20 {
  z-index: -20;
}

.-z-30 {
  z-index: -30;
}

.-z-40 {
  z-index: -40;
}

.m-2 {
  margin: .5rem;
}

.m-5 {
  margin: 1.25rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mt-2 {
  margin-top: .5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mt-8 {
  margin-top: 2rem;
}

.flex {
  display: flex;
}

.hidden {
  display: none;
}

.h-\[32px\] {
  height: 32px;
}

.h-full {
  height: 100%;
}

.max-h-\[48px\] {
  max-height: 48px;
}

.min-h-screen {
  min-height: 100vh;
}

.w-\[32px\] {
  width: 32px;
}

.w-full {
  width: 100%;
}

.min-w-\[16rem\] {
  min-width: 16rem;
}

.max-w-4xl {
  max-width: 56rem;
}

.max-w-6xl {
  max-width: 72rem;
}

.max-w-sm {
  max-width: 24rem;
}

.max-w-xl {
  max-width: 36rem;
}

.cursor-default {
  cursor: default;
}

.cursor-pointer {
  cursor: pointer;
}

.flex-col {
  flex-direction: column;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.overflow-hidden {
  overflow: hidden;
}

.bg-panel {
  background-color: #696e8580;
}

.bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.bg-section1 {
  background-image: url("section1.33d25b18.webp");
}

.bg-section2 {
  background-image: url("section2.fa1524b0.webp");
}

.bg-section3 {
  background-image: url("section3.c5a1090e.webp");
}

.bg-section4 {
  background-image: url("section4.7ac597d7.webp");
}

.bg-cover {
  background-size: cover;
}

.fill-primary {
  fill: #fff;
}

.p-2 {
  padding: .5rem;
}

.p-5 {
  padding: 1.25rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pl-2 {
  padding-left: .5rem;
}

.text-center {
  text-align: center;
}

.font-logo {
  font-family: Zen Dots;
}

.font-mono {
  font-family: Fira Code;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-\[20rem\] {
  font-size: 20rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.uppercase {
  text-transform: uppercase;
}

.tracking-\[0\.3rem\] {
  letter-spacing: .3rem;
}

.text-dark {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-primary {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.opacity-0 {
  opacity: 0;
}

.opacity-10 {
  opacity: .1;
}

.bg-blend-darken {
  background-blend-mode: darken;
}

.outline-1 {
  outline-width: 1px;
}

.outline-primary {
  outline-color: #fff;
}

*, :before, :after {
  box-sizing: border-box;
}

* {
  scroll-behavior: smooth;
  font: inherit;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  font-family: Montserrat, sans-serif;
  text-decoration: none;
}

.header {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  text-transform: uppercase;
  letter-spacing: .3em;
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  opacity: .8;
  width: 100%;
  padding: .25rem;
  font-family: Fira Code;
}

.btn-xl {
  width: 15rem;
  height: 5rem;
}

.panel {
  background: #696e8580;
  margin: 1rem;
  padding: 1rem;
}

.form-input {
  background: #696e8580;
  margin: .1rem;
  padding: .5rem;
}

.hide-spans span {
  display: none;
}

.crt:before {
  content: " ";
  z-index: 2;
  pointer-events: none;
  background: linear-gradient(#12101000 50%, #00000040 50%) 0 0 / 100% 2px, linear-gradient(90deg, #ff00000f, #00ff0005, #0000ff0f) 0 0 / 3px 100%;
  display: block;
  position: fixed;
  inset: 0;
}

.hover\:bg-dark:hover {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.hover\:text-primary:hover {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.hover\:outline:hover {
  outline-style: solid;
}

@media (width >= 640px) {
  .sm\:max-h-20 {
    max-height: 5rem;
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .sm\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }
}

@media (width >= 768px) {
  .md\:max-h-28 {
    max-height: 7rem;
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:text-6xl {
    font-size: 3.75rem;
    line-height: 1;
  }

  .md\:text-7xl {
    font-size: 4.5rem;
    line-height: 1;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

/*# sourceMappingURL=index.6371c73d.css.map */
